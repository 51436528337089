import { EmailOutlined, OpenInNew } from "@mui/icons-material";
import type { SxProps } from "@mui/material";
import { Button, styled, Typography } from "@mui/material";
import type { FC } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Styles from "@/assets/styles/Styles";
import BreadcrumbNode from "@/components/BreadcrumbNode";
import MainLayout from "@/framework/MainLayout";
import MainMenu from "@/framework/Menu/MainMenu";
import { useStore } from "@/Store";
import NewsFeed from "@/views/dashboard/NewsFeed";

const MessageContainerTemplate = styled("div")`
  display: flex;
  background-color: ${Styles.Colors.DASHBOARD_BG_COLOR};
  padding: 30px;
  flex-direction: column;
`;

const WelcomeContainer = styled(MessageContainerTemplate)`
  grid-column: 2;
  grid-row: 1/1;
`;

const HelpContainer = styled(MessageContainerTemplate)`
  grid-column: 2;
  grid-row: 2/2;
`;

const NewsfeedContainer = styled(MessageContainerTemplate)`
  grid-column: 1;
  grid-row: 1/4;
  height: 100%;
  overflow-y: auto;
`;

const OuterContainer = styled("div")`
  --element-gap: 25px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: repeat(3, 1fr);
  gap: var(--element-gap);
  margin-top: 74px;
  margin-left: 40px;
  max-width: calc(80ch + var(--element-gap) + 40ch);
  max-height: 60vh;
`;

const Column = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const Row = styled("div")`
  display: flex;
  margin-top: 16px;
  height: 40px;
  gap: 16px;
`;

const buttonStyling: SxProps = {
  textTransform: "none",
  color: "primary",
  borderColor: "inherit",
  justifyContent: "flex-start",
  ":hover": {
    borderColor: "inherit",
    borderWidth: "2px",
  },
};

const Img = styled("img")`
  height: 138px;
  aspect-ratio: 1/1;
  margin: 48px;
`;

const changeLogStyling: SxProps = {
  mt: 2,
  alignSelf: "center",
  color: "grey.800",
  textDecorationColor: "inherit",
  ":visited": {
    color: "grey.800",
    textDecorationColor: "inherit",
  },
  ":hover": {
    color: "grey.600",
    textDecorationColor: "inherit",
  },
};

export const DashboardView: FC = () => {
  const { state } = useStore();
  const { t } = useTranslation("common");
  const [menuOpen, setMenuOpen] = useState<boolean>(true);
  const name = state.user?.name || "";
  const label = name ? "views.dashboard.hello" : "views.dashboard.helloAnonymous";

  return (
    <MainLayout menuOpen={menuOpen} setMenuOpen={setMenuOpen} menuContent={<MainMenu />} hideBreadcrumbs>
      <BreadcrumbNode label="generic.dashboard" absolutePath="/dashboard" />
      <OuterContainer>
        <NewsfeedContainer>
          <NewsFeed />
        </NewsfeedContainer>
        <WelcomeContainer>
          <Typography variant="caption" fontSize={"16px"} fontWeight={"bold"}>
            {`${t("views.dashboard.welcome")} ${name}`}
          </Typography>
          <Typography whiteSpace={"pre-line"}>{t("views.dashboard.welcomeMessage")}</Typography>
        </WelcomeContainer>
        <HelpContainer>
          <Typography variant="caption" fontSize={"16px"} fontWeight={"bold"}>
            {t("views.dashboard.helpBoxCaption")}
          </Typography>
          <Button
            href={"https://akademi.ekultur.org/bundles/kulturio"}
            target="_blank"
            startIcon={<OpenInNew />}
            sx={buttonStyling}
          >
            {t("views.dashboard.digitalGuidance")}
          </Button>
          <Button href={"mailto:support@kulturit.no"} startIcon={<EmailOutlined />} sx={buttonStyling}>
            {t("views.dashboard.contactSupport")}
          </Button>
        </HelpContainer>
      </OuterContainer>
    </MainLayout>
  );
};

export default DashboardView;
