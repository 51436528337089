import type { FC, ReactNode } from "react";
import { styled, Drawer } from "@mui/material";
import Styles from "@/assets/styles/Styles";

interface StyleProps {
  width?: string;
  position: "left" | "right";
  backgroundColor?: string;
}

export interface PersistentDrawerProps extends Partial<StyleProps> {
  children: ReactNode;
  drawerContent: ReactNode;
  open?: boolean;
  isOpenedInsideModal?: boolean;
}

const StyledAside = styled("aside")(({ width, backgroundColor }: Omit<StyleProps, "position">) => ({
  marginTop: "72px",
  position: "relative",
  width,
  height: "100%",
  overflow: "auto",
  background: backgroundColor,
}));

const StyledMainContent = styled("div")(
  ({ open, width, position }: Partial<PersistentDrawerProps>) => `
    height: 100%;
    width: 100%;
    padding: 0;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: stretch;
    transition: padding 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    padding-left: ${!!open && position === "left" ? width : ""};
    padding-right: ${!!open && position === "right" ? width : ""};
  `
);

const openedInModalStyling = {
  "--openedInModalStyling-height-delta": "135px",
  "--mui-default-modal-margin": "32px",
  "& .MuiDrawer-paper": {
    height: "calc(100vh - var(--openedInModalStyling-height-delta))",
    top: "var(--mui-default-modal-margin)", // mui default modal margin
    right: "var(--mui-default-modal-margin)",
  },
  "& menu": {
    bottom: "calc(var(--openedInModalStyling-height-delta) - var(--mui-default-modal-margin))",
  },
};

export const PersistentDrawer: FC<PersistentDrawerProps> = ({
  open = true,
  position = "left",
  width = "25vw",
  children,
  drawerContent,
  backgroundColor,
  isOpenedInsideModal = false,
}) => (
  <>
    <Drawer sx={isOpenedInsideModal ? openedInModalStyling : {}} anchor={position} variant="persistent" open={open}>
      <StyledAside width={width} backgroundColor={backgroundColor || Styles.Colors.DRAWER_BACKGROUND_COLOR}>
        {drawerContent}
      </StyledAside>
    </Drawer>

    <StyledMainContent open={open} width={width} position={position}>
      {children}
    </StyledMainContent>
  </>
);

export default PersistentDrawer;
