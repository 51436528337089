import { useMemo } from "react";
import type { SxProps } from "@mui/material";
import { styled, Typography } from "@mui/material";
import type { UiSchema, RJSFSchema, TemplatesType } from "@rjsf/utils";
import { useTranslation } from "react-i18next";
import validator from "@rjsf/validator-ajv8";
import type { FormProps, IChangeEvent } from "@rjsf/core";
import Form from "@rjsf/core";
import { Fields } from "./Fields";
import ArrayFieldTemplate from "./templates/array/ArrayFieldTemplate";
import { FieldTemplate } from "./templates/field/FieldTemplate";
import ObjectFieldTemplate from "./templates/object/ObjectFieldTemplate";
import { Widgets } from "./widgets/Widgets";
import { createSchemaTranslator, parseSchema, translatableFieldNames } from "@/utils/schema";
import { useStore } from "@/Store";
import Settings from "@/Settings";
import { useKeyPress } from "@/hooks/useKeyPress";
import type DocumentReference from "@/declarations/models/DocumentReference";
import FormButtons from "@/components/FormButtons";

export interface GlobalObjectProperties {
  [k: string]: any;
}

export interface KioFormContext {
  applicationInstanceId?: number;
  selectedLocale: string;
  debounceTime: number;
  variant: "standard" | "outlined" | "filled";
  globalProperties: GlobalObjectProperties;
  isOpenedInsideModal?: boolean;
}

export interface KioFormProps<T> extends Pick<FormProps<T | null>, "formData" | "children"> {
  schema: string | RJSFSchema;
  uiSchema: string | UiSchema;
  globalDefsSchema?: RJSFSchema;
  onChange?: (formData: T) => void;
  onSubmit: (formData: T) => void;
  onCancel?: () => void;
  selectedLocale?: string;
  onReferencesChange?: (refs: DocumentReference[]) => void;
  sx?: SxProps;
  isOpenedInsideModal?: boolean;
}

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  .errors {
    margin: 0 16px;
    color: crimson;
  }
`;

export const FORM_ID_PREFIX = "kio-form-root";

const templates: Partial<TemplatesType> = {
  ObjectFieldTemplate,
  ArrayFieldTemplate,
  FieldTemplate,
};

// Inspect formData and extract all the objects that looks like a DocumentReference
function extractDocumentReferences(formData: any): DocumentReference[] {
  function reducer(acc: DocumentReference[], [_, v]: [any, any]): DocumentReference[] {
    if (v) {
      if (typeof v === "object" && "to_document_id" in v && "reference_key" in v) {
        // this object looks like a relation object, assume it is
        if (
          v.to_document_id &&
          v.reference_key &&
          !acc.find(
            ({ to_document_id, reference_key }) =>
              to_document_id === v.to_document_id && reference_key === v.reference_key
          )
        ) {
          // did not find the reference in the accumulator, add!
          return [...acc, { ...v }];
        } else {
          // reference already added, or invalid format. ignore.
          return acc;
        }
      }

      // normal object/array, keep reducing
      if (typeof v === "object") {
        return Object.entries(v).reduce(reducer, acc);
      }
    }
    return acc;
  }

  if (typeof formData === "object") {
    return Object.entries(formData).reduce(reducer, []);
  }
  return [];
}

export const KioForm = <T extends object>({
  children,
  formData,
  schema,
  uiSchema,
  onChange,
  onSubmit,
  selectedLocale,
  onCancel,
  onReferencesChange,
  sx,
  isOpenedInsideModal = false,
}: KioFormProps<T>) => {
  const { t: commonTrans } = useTranslation("common");
  const { t: dbTrans } = useTranslation("db");
  const translateMapper = useMemo(
    () => createSchemaTranslator(dbTrans, commonTrans, translatableFieldNames),
    [commonTrans, dbTrans]
  );
  const { state } = useStore();
  const enterPress = useKeyPress("Enter");

  const defaultFormContext: KioFormContext = {
    selectedLocale: selectedLocale || Settings.DEFAULT_LOCALE,
    applicationInstanceId: state.cmsContextInstance?.id,
    debounceTime: 400,
    globalProperties: {},
    variant: "filled",
    isOpenedInsideModal: isOpenedInsideModal,
  };

  const schemaAsObj = useMemo<RJSFSchema>(() => {
    const _schema = parseSchema(schema) as RJSFSchema;
    // prevents schemapreview from crashing or showing error message.
    if (!Object.keys(_schema).length || !Object.keys(state.globalSchemaDefinitions).length) {
      return {};
    }
    _schema.definitions = _schema.definitions || {};
    _schema.definitions["global"] = state.globalSchemaDefinitions;
    return _schema;
  }, [schema, state.globalSchemaDefinitions]);

  const uiSchemaAsObj = useMemo<UiSchema>(
    () => translateMapper({ ...parseSchema(uiSchema) } as UiSchema),
    [uiSchema, translateMapper]
  );

  // TODO: 23/11/2021 create global definition for document-relations
  //  and document usage on wiki

  const handleChange = (event: IChangeEvent) => {
    onChange?.(event.formData ?? {});

    if (onReferencesChange) {
      onReferencesChange(extractDocumentReferences(event.formData));
    }
  };

  if (!schema || !uiSchema) {
    return <Typography>{commonTrans("incorrect schema")}</Typography>;
  }

  return (
    <StyledForm
      idPrefix={FORM_ID_PREFIX} // can't be "root" since React uses it for the root-component (see index.tsx and index.html)
      schema={schemaAsObj}
      uiSchema={uiSchemaAsObj}
      formData={formData}
      templates={templates}
      validator={validator}
      widgets={Widgets}
      fields={Fields}
      onSubmit={(event) => !enterPress && onSubmit((event.formData as T) ?? ({} as T))}
      onChange={handleChange}
      formContext={defaultFormContext}
      noHtml5Validate
      sx={sx}
    >
      {children || <FormButtons onCancel={onCancel} onSubmit={() => {}} />}
    </StyledForm>
  );
};

export default KioForm;
