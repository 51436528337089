import { type FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, DialogActions } from "@mui/material";
import { useModalManager } from "./store";
import { FullScreenDialog } from "@/components/MediaSelector/MediaTypeTabPanel/FullScreenDialog";
import { ModalWrapper } from "@/components/MediaSelector/MediaTypeTabPanel/ModalWrapper";

import EditDocumentLoader from "@/views/cms/EditorView/EditDocumentLoader";
import { EditorStoreProvider } from "@/EditorContextProvider";
import { useModalId } from "@/framework/ModalManager/ModalIdProvider";

export const EditDocumentLoaderModal: FC = () => {
  const currentModalId = useModalManager((s) => s.editDocumentLoader.currentModalId);
  const modal = useModalManager((s) => s.editDocumentLoader.modals[currentModalId]);
  const isOpen = useModalManager((s) => s.editDocumentLoader.isOpen);
  const { t } = useTranslation("common");
  const { setModalId } = useModalId();
  const onCloseModal = () => {
    if (!modal?.props.isDirty || window.confirm(t("generic.unsavedChangesMessage"))) modal?.meta.onClose();
  };
  useEffect(() => {
    setModalId(currentModalId);
  }, [currentModalId]);

  return (
    <FullScreenDialog maxWidth={"xl"} fullWidth open={isOpen} onClose={onCloseModal} scroll={"paper"}>
      <EditorStoreProvider>
        <ModalWrapper>
          <ModalWrapper>{modal?.props && <EditDocumentLoader modalEditor {...modal.props} />}</ModalWrapper>
        </ModalWrapper>
        <DialogActions>
          <Button type="button" color="primary" variant="contained" onClick={onCloseModal}>
            {t("generic.close")}
          </Button>
        </DialogActions>
      </EditorStoreProvider>
    </FullScreenDialog>
  );
};
