import type { FC } from "react";
import type { RJSFSchema, UiSchema } from "@rjsf/utils";
import { styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import type { FallbackProps } from "react-error-boundary";
import { ErrorBoundary } from "react-error-boundary";
import i18next from "i18next";
import Styles from "@/assets/styles/Styles";
import Nuke from "@/components/Nuke";
import KioForm from "@/framework/KioForm/KioForm";
import { getLanguageCode } from "@/utils/obj";
import { ModalIdProvider } from "@/framework/ModalManager/ModalIdProvider";

export interface SchemaPreviewProps {
  schema: RJSFSchema;
  uiSchema: UiSchema;
}

const PreviewContainer = styled("div")(({ theme }) => ({
  borderLeft: `2px solid ${Styles.Colors.PREVIEW_BORDER_COLOR}`,
  background: `${Styles.Colors.FORM_BACKGROUND_COLOR}`,
  width: "100%",
  height: "100%",
  overflow: "auto",
}));

const noop = () => {};

export const SchemaPreview: FC<SchemaPreviewProps> = ({ schema, uiSchema }) => {
  const { t } = useTranslation("common");
  if (!schema) {
    return (
      <PreviewContainer>
        <Nuke errorText={t("views.admin.schemaPreview.missingSchema")} />
      </PreviewContainer>
    );
  }

  return (
    <PreviewContainer>
      <ErrorBoundary
        FallbackComponent={(props: FallbackProps) => (
          <Nuke fallbackProps={props} errorText={t("views.admin.schemaPreview.invalidSchema")} />
        )}
      >
        <ModalIdProvider>
          <KioForm
            selectedLocale={getLanguageCode(i18next.language)}
            schema={schema}
            uiSchema={uiSchema}
            onChange={noop}
            onSubmit={noop}
          >
            <span />
          </KioForm>
        </ModalIdProvider>
      </ErrorBoundary>
    </PreviewContainer>
  );
};

export default SchemaPreview;
