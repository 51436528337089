import ColorThief from "colorthief";

/**
 * Extracts the dominant color from an image file.
 * @param file - The image file.
 * @param quality - The quality of the color extraction. Ex. 10 uses every 10 pixels.
 * @returns A promise that resolves to an array [r, g, b].
 */
export function getDominantColorFromFile(file: File, quality: number = 10): Promise<string> {
  return new Promise((resolve, reject) => {
    // Create an image element
    const img = new Image();

    // If needed, set crossOrigin (sometimes required for CORS)
    img.crossOrigin = "Anonymous";

    // Create a temporary URL for the file object
    img.src = URL.createObjectURL(file);

    // When the image loads, get the color using ColorThief
    img.onload = () => {
      try {
        const colorThief = new ColorThief();
        // The getColor method is synchronous but requires the image to be loaded
        const dominantColor = colorThief.getColor(img, quality);
        if (!dominantColor) {
          reject(new Error("Failed to extract dominant color"));
          return;
        }
        const dominantColorHex = `#${(dominantColor ?? [])
          .map((c: number) => c.toString(16).padStart(2, "0"))
          .join("")}`;
        resolve(dominantColorHex);
      } catch (err) {
        reject(err);
      }
    };

    img.onerror = (err) => {
      reject(new Error("Failed to load the image"));
    };
  });
}
