/*
 * Object related util
 */
import { deepEqual } from "fast-equals";
import Settings from "@/Settings";

/**
 *
 * @param item
 * @param path
 * @param defaultValue
 * @param pathSeparator
 * @param index
 */
export function getValue(
  item?: any,
  path?: string,
  defaultValue: any = null,
  pathSeparator: string = ".",
  index?: number
): any {
  if (!path || item == null || typeof item !== "object") {
    return item;
  }

  // in jsonpath, $ is the "root" object
  if (path.startsWith("$.")) {
    path = path.substring(2);
  }

  const [currentKey, ...remainingKeys] = path.split(pathSeparator);

  const getSubValue = (i: any) => {
    let index: number | null = null;
    let newCurrentKey: string | null = null;

    if (currentKey.includes("[")) {
      const splitted = currentKey.split("[");
      if (splitted?.length === 2) {
        [newCurrentKey] = splitted;
      }
      index = Number(splitted[1].slice(0, -1));
    }

    if (newCurrentKey && index !== null) {
      return getValue(i[newCurrentKey], remainingKeys.join(pathSeparator), null, pathSeparator, index);
    } else {
      return getValue(i[currentKey], remainingKeys.join(pathSeparator));
    }
  };

  if (Array.isArray(item) && Number.isNaN(Number(currentKey))) {
    if (typeof index === "number") {
      return getValue(item[index], path);
    } else {
      return item.map(getSubValue);
    }
  }

  return getSubValue(item) ?? defaultValue;
}

export function deepCompareFormData(initial: any, current: any) {
  return deepEqual(
    {
      ...initial,
      content: { ...initial.content, updated_at: null },
      updated_at: null,
    },
    {
      ...current,
      content: { ...current.content, updated_at: null },
      updated_at: null,
    }
  );
}

/**
 * Replace empty values with null for comparison
 * @param value - any value to be replaced with null if empty
 */
export function equateEmpty(value: any): any {
  if (!value && value !== 0 && value !== false) return null;
  if (typeof value === "object") {
    if (Array.isArray(value)) {
      if (!value.length) return null;
    } else if (!Object.keys(value).length) return null;
  }
  return value;
}

/**
 * Deeply compare two values
 * @param initial
 * @param current
 */
export function checkIsDirty(initial: any, current: any): boolean {
  // To stop check from returning true for unused fields
  initial = equateEmpty(initial);
  current = equateEmpty(current);

  if (initial === null || current === null || typeof initial !== typeof current || typeof initial !== "object") {
    return initial !== current;
  }
  if (Array.isArray(initial) || Array.isArray(current)) {
    if (initial?.length !== current?.length) {
      return true;
    }
    for (let i = 0; i < initial.length; i++) {
      if (checkIsDirty(initial[i], current[i])) {
        return true;
      }
    }
    return false;
  }
  const initialKeys = Object.keys(initial);
  const currentKeys = Object.keys(current);
  if (initialKeys.length !== currentKeys.length) {
    return true;
  }
  const allKeys = Object.keys([...initialKeys, ...currentKeys].reduce((acc, val) => ({ ...acc, [val]: 1 }), {}));
  for (const key of allKeys) {
    if (checkIsDirty(initial[key], current[key])) {
      return true;
    }
  }
  return false;
}
/**
 *
 * @param obj
 */
export function isObject(obj: any): boolean {
  return typeof obj === "object" && !Array.isArray(obj) && obj !== null;
}

/**
 * extract all string values from an object (recursively) from a selection of property names
 * @param o the object to extract strings from
 * @param filter list of field names to extract
 */
export function extractFieldStrings(o: object, filter: string[]): string[] {
  const result: string[] = [];
  Object.entries(o).forEach(([key, value]) => {
    if (filter.includes(key) && typeof value === "string") {
      result.push(value);
    } else if (typeof value === "object") {
      result.push(...extractFieldStrings(value, filter));
    }
  });
  return result;
}

/**
 * Helper to make sure we are displaying a string when displaying text that _might_ be localized;
 * {
 *   "no": "Norsk Tekst",
 *   "en": "English Text"
 * }
 *
 * @param preferredLanguage
 */
export function resolvePotentiallyLocalizedString(preferredLanguage: string) {
  return function resolve(stringOrObject?: any): string | undefined | null {
    if (stringOrObject == null || typeof stringOrObject === "string") {
      return stringOrObject;
    }
    if (isObject(stringOrObject)) {
      // preferred language is present, use that
      if (
        preferredLanguage in stringOrObject &&
        typeof stringOrObject[preferredLanguage] === "string" &&
        stringOrObject[preferredLanguage] !== ""
      ) {
        return stringOrObject[preferredLanguage];
      }
      // preferred language empty,
      // pick first non-empty based on priority order in supported locales,
      // add  indication that a different lang is used
      let retStr = "";
      Settings.SUPPORTED_CONTENT_LOCALES.forEach((locale) => {
        const str = stringOrObject[locale];
        if (typeof str === "string" && str !== "") {
          retStr = `${str} [${locale}]`;
          return;
        }
      });
      return retStr;
    }
  };
}

// https://stackoverflow.com/a/50951289
/**
 * Transforms a path ["a", "b", "c"] to a deep object {a: {b: {c: <value>}}}
 * @param path strings of property names
 * @param value value for the last property in the array
 */
export function objectFromPath(path: string[], value: any) {
  const reducer = (acc: any, v: string, index: number, arr: string[]) => ({
    [v]: index + 1 < arr.length ? acc : value,
  });
  return path.reduceRight(reducer, {} as any);
}

/**
 * Returns short language code. Returns default locale if the language is not supported.
 *
 * @param languageCode
 */
export function getLanguageCode(languageCode: string) {
  if (!languageCode) return Settings.DEFAULT_LOCALE;

  const langCode = languageCode.toLowerCase();
  if (Settings.SUPPORTED_APP_LOCALES.includes(langCode)) return langCode;

  const langCodeSplit = langCode.split("-");
  let newCode = "en";
  langCodeSplit.forEach((code) => {
    if (langCodeSplit[0] === "sv") {
      newCode = "sv";
      return;
    } else if (Settings.SUPPORTED_APP_LOCALES.includes(code)) {
      newCode = code;
      return;
    } else if (Settings.FALLS_BACK_TO_NO.includes(code)) {
      newCode = "no";
      return;
    }
  });
  return newCode;
}

export function findDeepPropertiesWithContent(obj: any, propNames: string | string[]): { [key: string]: boolean } {
  /**
   * Recursively check if the object 'obj' has a property (at any depth) with one of the names in 'propNames'
   * and that the property's value is not empty.
   *
   * @param obj - The object to check. Can be any object, including nested ones.
   * @param propNames - A string or an array of strings representing property names.
   * @return a dictionary with the property names as keys with values True if such a property exists and has content, False otherwise.
   */

  if (typeof propNames === "string") {
    propNames = [propNames];
  }

  const propNamesSet = new Set(propNames);

  const foundPropName: { [key: string]: boolean } = {};
  propNames.forEach((propName) => {
    foundPropName[propName] = false;
  });

  function isNonEmptyValue(value: any): boolean {
    if (value === null || value === undefined || value === "") return false;

    if (typeof value === "object") {
      if (Array.isArray(value)) {
        return value.length > 0;
      } else {
        return Object.keys(value).length > 0;
      }
    }

    return true;
  }

  function recursiveSearch(currentObj: any): boolean {
    if (propNamesSet.size === 0) {
      // All properties have been found; stop recursion
      return true;
    }

    if (currentObj && typeof currentObj === "object") {
      for (const key in currentObj) {
        if (Object.prototype.hasOwnProperty.call(currentObj, key)) {
          const value = currentObj[key];
          // Check if the current key is one of the property names we're looking for
          if (propNamesSet.has(key)) {
            if (isNonEmptyValue(value)) {
              foundPropName[key] = true;
              propNamesSet.delete(key); // Remove found property from the set
            }
            if (propNamesSet.size === 0) {
              // Found all properties; can exit early
              return true;
            }
          }
          // If the value is an object or array, recurse into it
          if (typeof value === "object" && value !== null) {
            if (recursiveSearch(value)) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }
  recursiveSearch(obj);

  return foundPropName;
}

export function filterNull<T>(a: T | undefined | null): a is T {
  return a != null;
}
