import { useEffect, useState } from "react";
import { useStore } from "@/Store";
import Api from "@/services/Api";
import type Menu from "@/declarations/models/Menu";

/**
 * useFetchApplicationMenu
 * Fetches the menu for tha current application. Mostly used for getting the menu for returning the menu name from a schema id.
 */
export const useFetchApplicationMenu = () => {
  const { state } = useStore();
  const applicationId = state.cmsContextInstance?.application_id;
  const [appMenus, setAppMenus] = useState<Menu[]>([]);

  useEffect(() => {
    const getMenus = async () => {
      if (applicationId) {
        const getApp = Api.getOneApplication(applicationId);
        const menus = await getApp
          .fetchDirect(null)
          .then((app) => app?.menu || [])
          .finally(getApp.abort);
        if (!active) return;
        setAppMenus(menus);
      }
    };
    let active = true;
    getMenus().then(() => (active = false));
  }, [applicationId]);

  return appMenus;
};
