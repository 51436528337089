import { useState, createContext, type ReactNode, type FC, useContext } from "react";

// Define the context type
interface ModalIdContextType {
  modalId: string | null;
  setModalId: (id: string | null) => void;
}

interface ModalIdProviderProps {
  children: ReactNode;
}

const ModalIdContext = createContext<ModalIdContextType | undefined>(undefined);

export const useModalId = (): ModalIdContextType => {
  const context = useContext(ModalIdContext);

  if (!context) {
    throw new Error("useModalId must be used within a ModalIdProvider");
  }
  return context;
};

export const ModalIdProvider: FC<ModalIdProviderProps> = ({ children }) => {
  const [modalId, setModalId] = useState<string | null>(null);

  return <ModalIdContext.Provider value={{ modalId, setModalId }}>{children}</ModalIdContext.Provider>;
};
