import { AdminApiRequest, HealthApiRequest, PortalApiRequest } from "@/declarations/ApiRequest";
import type { DMSPlaybackUrls } from "@/declarations/DMSPlaybackUrls";
import type { DMSUploadStatus } from "@/declarations/DMSUploadStatus";
import type ApiKey from "@/declarations/models/ApiKey";
import type Application from "@/declarations/models/Application";
import type ApplicationInstance from "@/declarations/models/ApplicationInstance";
import type { CreditType } from "@/declarations/models/Credit";
import type { DMSearchResult } from "@/declarations/models/DMSearchResult";
import type Document from "@/declarations/models/Document";
import type DocumentProcessedFields from "@/declarations/models/DocumentProcessedFields";
import type DocumentRelation from "@/declarations/models/DocumentRelation";
import type DocumentSearchHit from "@/declarations/models/DocumentSearchHit";
import type DocumentStatus from "@/declarations/models/DocumentStatus";
import type { HealthCheck } from "@/declarations/models/HealthCheck";
import type { KioUser } from "@/declarations/models/KioUser";
import type Locale from "@/declarations/models/Locale";
import type Media from "@/declarations/models/Media";
import type MediaType from "@/declarations/models/MediaType";
import type { MimeTypes } from "@/declarations/models/MimeTypes";
import type Owner from "@/declarations/models/Owner";
import type QrCode from "@/declarations/models/QrCode";
import type { Role } from "@/declarations/models/Role";
import type Schema from "@/declarations/models/Schema";
import type Translation from "@/declarations/models/Translation";
import type { TranslationKey } from "@/declarations/models/TranslationKey";
import type NonPaginatedItemsResult from "@/declarations/NonPaginatedItemsResult";
import type PaginationResult from "@/declarations/PaginationResult";
import type { QueryParams } from "@/declarations/QueryParams";
import type { RequestContext } from "@/declarations/RequestContext";
import InstanceUserRole from "@/declarations/models/InstanceUserRole";

/**
 * Reference to all base-endpoints
 */
export enum ApiResource {
  API_KEYS = "api-keys",
  APPLICATIONS = "applications",
  DOCUMENTS = "documents",
  HEALTH_CHECK = "check",
  INSTANCES = "instances",
  LOCALES = "locales",
  MEDIA = "media",
  OWNERS = "owners",
  QRCODES = "qr-codes",
  ROLES = "roles",
  SCHEMAS = "schemas",
  TRANSLATIONS = "translations",
  TRANSLATION_KEYS = "translations/keys",
  USERS = "users",
}

interface PaginationQueryParams extends QueryParams {
  page: number;
  page_size: number;
}

type GetAllInstanceUserRoleQueryParams = PaginationQueryParams;

interface GetAllInstanceQueryParams extends PaginationQueryParams {
  owner_id: number;
  application_id: number;
  user_id: number;
  include_expired: boolean;
}

interface GetAllAPIKeysQueryParams extends PaginationQueryParams {
  application_id: number;
  application_instance_id: number;
}

type GetAllLocalesQueryParams = PaginationQueryParams;

interface GetAllTranslationsQueryParams extends PaginationQueryParams {
  language_code: string;
  translation_key: string;
}

interface GetAllTranslationKeysQueryParams extends PaginationQueryParams {
  search: string;
}

interface GetAllOwnersQueryParams extends PaginationQueryParams {
  only_edit_privileges: boolean;
}

type GetAllApplicationsQueryParams = PaginationQueryParams;

interface GetAllSchemasQueryParams extends PaginationQueryParams {
  application_id: number;
}

interface GetAllDocumentsQueryParams extends PaginationQueryParams {
  schema_ids: number | number[];
  application_instance_id: number;
  status: DocumentStatus;
  created_at_from: string;
  created_at_to: string;
  updated_at_from: string;
  updated_at_to: string;
  created_by: number;
  updated_by: number;
  deleted_by: number;
  search: string;
  sort_by_title: boolean;
  sort_by_internal_title: boolean;
  locale: string;
  is_deleted: boolean;
  include_deleted: boolean;
}

interface GetAllPortalDocuementsQueryParams extends GetAllDocumentsQueryParams {
  path_value: string;
  sort: string;
  order_asc: boolean;
}

interface GetDocumentsAsRelationQueryParams extends QueryParams {
  d: number | number[];
}

interface GetProcessedFieldsOfDocumentsQueryParams extends QueryParams {
  d: number | number[];
}

interface GetAllMediaQueryParams extends PaginationQueryParams {
  media_types: MediaType | MediaType[];
  owner_id: number;
  application_instance_id: number;
  search: string;
  order_by?: string;
  order_asc?: boolean;
  created_by?: number;
  updated_by?: number;
  deleted_by?: number;
  sort_by_title?: boolean;
  locale?: string;
  is_deleted: boolean;
  include_deleted: boolean;
}
interface GetMediaUniqueQueryParams extends QueryParams {
  identifier: string;
  owner_id: number;
  application_instance_id: number;
  media_type: MediaType;
}

interface GetMediaUrlsQueryParams extends QueryParams {
  m: number | number[];
}

interface GetAllQrCodesQueryParams extends PaginationQueryParams {
  application_instance_id?: number;
}

interface GetQRCodeImageQueryParams {
  code: string;
  include_logo?: boolean;
}

type GetAllUsersQueryParams = PaginationQueryParams;

interface GetUsersByInstanceIdQueryParams extends PaginationQueryParams {
  application_instance_id: number;
}

interface GetUsersByInstanceIdQueryParams extends PaginationQueryParams {
  application_instance_id: number;
}

interface SearchDMQueryParams extends Partial<PaginationQueryParams> {
  query: string;
  dm_type?: string;
  open_licenses_only?: boolean;
  include_no_pictures?: boolean;
}

/**
 * All available API-endpoints.
 *
 * Example of usage in a React component:
 * <pre>
 *     const [roles, setRoles] = useState<Array<Role>>([]);
 *     useEffect(() => {
 *       const request = Api.getAllRoles();
 *       const [response, error, source] = await request.fetch()
 *       if (source.name == 'AbortError) {
 *           console.info("Request was aborted")
 *       } else if (error) {
 *           console.error("Unable to fetch roles", error, source)
 *       }
 *       setRoles(error ? [] : response);
 *       return () => request.abort();
 *     }, []);
 * </pre>
 * Or use fetchDirect() to simplify all the checks:
 * <pre>
 *     const [roles, setRoles] = useState<Array<Role>>([]);
 *     useEffect(() => {
 *       const request = Api.getAllRoles();
 *       setRoles(await request.fetchDirect([]));
 *       return () => request.abort();
 *     }, []);
 * </pre>
 */
abstract class Api {
  // ***************************
  // ******** ROLES API ********
  // ***************************

  /**
   * Get all available roles
   */
  public static getAllRoles(): RequestContext<PaginationResult<Role>> {
    return new AdminApiRequest<PaginationResult<Role>>(ApiResource.ROLES).withAuth().get();
  }

  // ***************************
  // ******** USERS API ********
  // ***************************

  /**
   * Get all users
   * @param queryParams
   */
  public static getAllUsers(queryParams?: Partial<GetAllUsersQueryParams>): RequestContext<PaginationResult<KioUser>> {
    return new AdminApiRequest<PaginationResult<KioUser>>(ApiResource.USERS).withParams(queryParams).withAuth().get();
  }

  /**
   * Get the available information about the user with id
   * @param userId
   */
  public static getOneUser(userId: number): RequestContext<KioUser> {
    return new AdminApiRequest<KioUser>(ApiResource.USERS, userId).withAuth().get();
  }

  /**
   * @param user
   */
  public static createUser(user: Partial<KioUser>): RequestContext<KioUser> {
    return new AdminApiRequest<KioUser>(ApiResource.USERS).withAuth().withBody(user).post();
  }

  /**
   * @param user
   */
  public static updateUser(user: KioUser): RequestContext<KioUser> {
    return new AdminApiRequest<KioUser>(ApiResource.USERS, user?.id || 0).withAuth().withBody(user).put();
  }

  /**
   * Get the available information about the logged-in user
   */
  public static getUserInfo(): RequestContext<KioUser> {
    return new AdminApiRequest<KioUser>(ApiResource.USERS, "user-info").withAuth().get();
  }

  /**
   * Ban user with id
   * @param userId
   */
  public static banUser(userId: number): RequestContext<KioUser> {
    return new AdminApiRequest<KioUser>(ApiResource.USERS, "ban", userId).withAuth().get();
  }

  /**
   * Unban user with id
   * @param userId
   */
  public static unbanUser(userId: number): RequestContext<KioUser> {
    return new AdminApiRequest<KioUser>(ApiResource.USERS, "unban", userId).withAuth().get();
  }

  // ****************************
  // ******* INSTANCE API *******
  // ****************************

  /**
   *
   */
  public static getAllInstances(
    queryParams?: Partial<GetAllInstanceQueryParams>
  ): RequestContext<PaginationResult<ApplicationInstance>> {
    return new AdminApiRequest<PaginationResult<ApplicationInstance>>(ApiResource.INSTANCES)
      .withParams(queryParams)
      .withAuth()
      .get();
  }

  /**
   * @param id
   */
  public static getOneInstance(id: number): RequestContext<ApplicationInstance> {
    return new AdminApiRequest<ApplicationInstance>(ApiResource.INSTANCES, id).withAuth().get();
  }

  /**
   * @param instance
   */
  public static createInstance(instance: Partial<ApplicationInstance>): RequestContext<ApplicationInstance> {
    return new AdminApiRequest<ApplicationInstance>(ApiResource.INSTANCES).withAuth().withBody(instance).post();
  }

  /**
   * @param instance
   */
  public static updateInstance(instance: ApplicationInstance): RequestContext<ApplicationInstance> {
    return new AdminApiRequest<ApplicationInstance>(ApiResource.INSTANCES, instance?.id || 0)
      .withAuth()
      .withBody(instance)
      .put();
  }

  /**
   * @param id
   */
  public static deleteInstance(id: number): RequestContext<null> {
    return new AdminApiRequest<null>(ApiResource.INSTANCES, id).withAuth().delete();
  }

  /**
   * Undelete a deleted instance
   * @param id
   */
  public static undeleteInstance(id: number): RequestContext<null> {
    return new AdminApiRequest<null>(ApiResource.INSTANCES, id, "undelete").withAuth().post();
  }

  /**
   *
   * @param ownerSlug
   * @param appSlug
   */
  public static getInstanceBySlugs(ownerSlug: string, appSlug: string): RequestContext<ApplicationInstance> {
    return new AdminApiRequest<ApplicationInstance>(ApiResource.INSTANCES, ownerSlug, appSlug).withAuth().get();
  }

  /**
   *
   * @param queryParams
   */
  public static getUsersByInstanceId(
    queryParams?: GetUsersByInstanceIdQueryParams
  ): RequestContext<PaginationResult<InstanceUserRole>> {
    return new AdminApiRequest<PaginationResult<InstanceUserRole>>(ApiResource.INSTANCES, "users")
      .withAuth()
      .withParams(queryParams)
      .get();
  }

  public static getSharedInstances(
    sharedWithInstance: number,
    queryParams?: {
      type?: "document" | "media";
    }
  ): RequestContext<PaginationResult<ApplicationInstance>> {
    return new AdminApiRequest<PaginationResult<ApplicationInstance>>(
      ApiResource.INSTANCES,
      sharedWithInstance,
      "shared-instances"
    )
      .withParams(queryParams)
      .withAuth()
      .get();
  }

  /**
   * @param id
   */
  public static setInstanceToExpired(id: number): RequestContext<null> {
    return new AdminApiRequest<null>(ApiResource.INSTANCES, "expired", id).withAuth().delete();
  }

  /**
   *
   * @param instanceId
   * @param userId
   * @param role
   */
  public static addUserToInstance(userId: number, instanceId: number, role: Role): RequestContext<null> {
    return new AdminApiRequest<null>(ApiResource.INSTANCES, instanceId, "user", userId, "role", role).withAuth().post();
  }

  /**
   *
   * @param instanceId
   * @param userId
   * @param role
   */
  public static updateRoleToUsersInstance(userId: number, instanceId: number, role: Role): RequestContext<null> {
    return new AdminApiRequest<null>(ApiResource.INSTANCES, instanceId, "user", userId, "role", role).withAuth().put();
  }

  /**
   *
   * @param instanceId
   * @param userId
   */
  public static removeUserFromInstance(userId: number, instanceId: number): RequestContext<null> {
    return new AdminApiRequest<null>(ApiResource.INSTANCES, instanceId, "user", userId).withAuth().delete();
  }

  /**
   *
   * @param queryParams
   */
  public static getAllInstanceUserRoles(
    queryParams?: Partial<GetAllInstanceUserRoleQueryParams>
  ): RequestContext<PaginationResult<InstanceUserRole>> {
    return new AdminApiRequest<PaginationResult<InstanceUserRole>>(ApiResource.INSTANCES, "user-roles")
      .withAuth()
      .withParams(queryParams)
      .get();
  }

  /**
   *
   * @param queryParams
   */
  public static getUsersByInstanceId2(
    queryParams?: GetUsersByInstanceIdQueryParams
  ): RequestContext<PaginationResult<InstanceUserRole>> {
    return new AdminApiRequest<PaginationResult<InstanceUserRole>>(ApiResource.INSTANCES, "users")
      .withAuth()
      .withParams(queryParams)
      .get();
  }

  // ****************************
  // ******** API-KEY API *******
  // ****************************

  /**
   *
   */
  public static getAllApiKeys(
    queryParams?: Partial<GetAllAPIKeysQueryParams>
  ): RequestContext<PaginationResult<ApiKey>> {
    return new AdminApiRequest<PaginationResult<ApiKey>>(ApiResource.API_KEYS).withParams(queryParams).withAuth().get();
  }

  /**
   * @param id
   */
  public static getOneApiKey(id: number): RequestContext<ApiKey> {
    return new AdminApiRequest<ApiKey>(ApiResource.API_KEYS, id).withAuth().get();
  }

  /**
   * @param apiKey
   */
  public static createApiKey(apiKey: Partial<ApiKey>): RequestContext<ApiKey> {
    return new AdminApiRequest<ApiKey>(ApiResource.API_KEYS).withAuth().withBody(apiKey).post();
  }

  /**
   * @param apiKey
   */
  public static updateApiKey(apiKey: ApiKey): RequestContext<ApiKey> {
    return new AdminApiRequest<ApiKey>(ApiResource.API_KEYS, apiKey?.id || 0).withAuth().withBody(apiKey).put();
  }

  /**
   * @param id
   */
  public static deleteApiKey(id: number): RequestContext<null> {
    return new AdminApiRequest<null>(ApiResource.API_KEYS, id).withAuth().delete();
  }

  /**
   * Undelete a deleted apiKey
   * @param id
   */
  public static undeleteApiKey(id: number): RequestContext<null> {
    return new AdminApiRequest<null>(ApiResource.API_KEYS, id, "undelete").withAuth().post();
  }

  /**
   *
   * @param apiKeyId
   * @param instanceId
   */
  public static grantApiKeyAccessToApplicationInstance(apiKeyId: number, instanceId: number): RequestContext<null> {
    return new AdminApiRequest<null>(ApiResource.API_KEYS, apiKeyId, "instance", instanceId).withAuth().put();
  }

  /**
   *
   * @param apiKeyId
   * @param instanceId
   */
  public static revokeApiKeyAccessToApplicationInstance(apiKeyId: number, instanceId: number): RequestContext<null> {
    return new AdminApiRequest<null>(ApiResource.API_KEYS, apiKeyId, "instance", instanceId).withAuth().delete();
  }

  // ****************************
  // ******** LOCALE API ********
  // ****************************

  /**
   *
   */
  public static getAllLocales(
    queryParams?: Partial<GetAllLocalesQueryParams>
  ): RequestContext<PaginationResult<Locale>> {
    return new AdminApiRequest<PaginationResult<Locale>>(ApiResource.LOCALES).withParams(queryParams).withAuth().get();
  }

  /**
   * @param id
   */
  public static getOneLocale(id: number): RequestContext<Locale> {
    return new AdminApiRequest<Locale>(ApiResource.LOCALES, id).withAuth().get();
  }

  /**
   * @param locale
   */
  public static createLocale(locale: Partial<Locale>): RequestContext<Locale> {
    return new AdminApiRequest<Locale>(ApiResource.LOCALES).withAuth().withBody(locale).post();
  }

  /**
   * @param locale
   */
  public static updateLocale(locale: Locale): RequestContext<Locale> {
    return new AdminApiRequest<Locale>(ApiResource.LOCALES, locale?.id || 0).withAuth().withBody(locale).put();
  }

  // ****************************
  // ***** TRANSLATION API ******
  // ****************************

  /**
   *
   */
  public static getAllTranslations(
    queryParams?: Partial<GetAllTranslationsQueryParams>
  ): RequestContext<PaginationResult<Translation>> {
    return new AdminApiRequest<PaginationResult<Translation>>(ApiResource.TRANSLATIONS)
      .withParams(queryParams)
      .withAuth()
      .get();
  }

  /**
   *
   * @param translationKey
   * @param countryCode
   */
  public static getTranslation(translationKey: string, countryCode: string): RequestContext<Translation> {
    return new AdminApiRequest<Translation>(ApiResource.TRANSLATIONS, translationKey, countryCode).withAuth().get();
  }

  /**
   *
   * @param translationKey
   * @param languageCode
   * @param translation
   */
  public static updateTranslation(
    translationKey: string,
    languageCode: string,
    translation: string
  ): RequestContext<Translation> {
    return new AdminApiRequest<Translation>(ApiResource.TRANSLATIONS, translationKey, languageCode)
      .withAuth()
      .withBody(translation)
      .put();
  }

  /**
   *
   * @param translationKey
   * @param countryCode
   */
  public static deleteTranslation(translationKey: string, countryCode: string): RequestContext<null> {
    return new AdminApiRequest<null>(ApiResource.TRANSLATIONS, translationKey, countryCode).withAuth().delete();
  }

  /**
   *
   * @param queryParams
   */
  public static getAllTranslationKeys(
    queryParams?: Partial<GetAllTranslationKeysQueryParams>
  ): RequestContext<PaginationResult<TranslationKey>> {
    return new AdminApiRequest<PaginationResult<TranslationKey>>(ApiResource.TRANSLATION_KEYS)
      .withParams(queryParams)
      .withAuth()
      .get();
  }

  /**
   *
   * @param translation_key
   */
  public static createTranslationKey(translation_key: string): RequestContext<TranslationKey> {
    return new AdminApiRequest<TranslationKey>(ApiResource.TRANSLATION_KEYS, translation_key).withAuth().post();
  }

  /**
   * @param translationKeys
   */
  public static createTranslationKeysIfMissing(translationKeys: string[]): RequestContext<null> {
    return new AdminApiRequest<null>(ApiResource.TRANSLATION_KEYS, "create-if-missing")
      .withAuth()
      .withBody(translationKeys)
      .put();
  }

  /**
   *
   * @param id
   */
  public static getTranslationKeyById(id: number): RequestContext<TranslationKey> {
    return new AdminApiRequest<TranslationKey>(ApiResource.TRANSLATION_KEYS, id).withAuth().get();
  }

  /**
   *
   * @param id
   * @param translationKey
   */
  public static updateTranslationKey(id: number, translationKey: TranslationKey): RequestContext<TranslationKey> {
    return new AdminApiRequest<TranslationKey>(ApiResource.TRANSLATION_KEYS, id)
      .withAuth()
      .withBody(translationKey)
      .put();
  }

  /**
   *
   * @param id
   */
  public static deleteTranslationKey(id: number): RequestContext<null> {
    return new AdminApiRequest<null>(ApiResource.TRANSLATION_KEYS, id).withAuth().delete();
  }

  /**
   *
   * @param key
   */
  public static validateTranslationKey(key: string): RequestContext<null> {
    return new AdminApiRequest<null>(ApiResource.TRANSLATION_KEYS, "validate", key).withAuth().get();
  }

  // ****************************
  // ******** OWNER API *********
  // ****************************

  /**
   *
   */
  public static getAllOwners(queryParams?: Partial<GetAllOwnersQueryParams>): RequestContext<PaginationResult<Owner>> {
    return new AdminApiRequest<PaginationResult<Owner>>(ApiResource.OWNERS).withParams(queryParams).withAuth().get();
  }

  /**
   * @param id
   */
  public static getOneOwner(id: number): RequestContext<Owner> {
    return new AdminApiRequest<Owner>(ApiResource.OWNERS, id).withAuth().get();
  }

  /**
   * @param owner
   */
  public static createOwner(owner: Partial<Owner>): RequestContext<Owner> {
    return new AdminApiRequest<Owner>(ApiResource.OWNERS).withAuth().withBody(owner).post();
  }

  /**
   * @param owner
   */
  public static updateOwner(owner: Owner): RequestContext<Owner> {
    return new AdminApiRequest<Owner>(ApiResource.OWNERS, owner?.id || 0).withAuth().withBody(owner).put();
  }

  /**
   * @param owner
   */
  public static updateOwnerLimited(owner: Owner): RequestContext<Owner> {
    return new AdminApiRequest<Owner>(ApiResource.OWNERS, owner?.id || 0, "limited").withAuth().withBody(owner).put();
  }

  /**
   * @param id
   */
  public static deleteOwner(id: number): RequestContext<null> {
    return new AdminApiRequest<null>(ApiResource.OWNERS, id).withAuth().delete();
  }

  /**
   * Undelete a deleted owner
   * @param id
   */
  public static undeleteOwner(id: number): RequestContext<null> {
    return new AdminApiRequest<null>(ApiResource.OWNERS, id, "undelete").withAuth().post();
  }

  // ****************************
  // ***** APPLICATION API ******
  // ****************************

  /**
   *
   */
  public static getAllApplications(
    queryParams?: Partial<GetAllApplicationsQueryParams>
  ): RequestContext<PaginationResult<Application>> {
    return new AdminApiRequest<PaginationResult<Application>>(ApiResource.APPLICATIONS)
      .withParams(queryParams)
      .withAuth()
      .get();
  }

  /**
   * application_id
   */
  public static getAllUsersByApplicationCSV(queryParams?: Partial<GetAllSchemasQueryParams>): RequestContext<Blob> {
    return new AdminApiRequest<Blob>(ApiResource.USERS, "csv").withParams(queryParams).withAuth().get();
  }

  /**
   * @param id
   */
  public static getOneApplication(id: number): RequestContext<Application> {
    return new AdminApiRequest<Application>(ApiResource.APPLICATIONS, id).withAuth().get();
  }

  /**
   * @param application
   */
  public static createApplication(application: Partial<Application>): RequestContext<Application> {
    return new AdminApiRequest<Application>(ApiResource.APPLICATIONS).withAuth().withBody(application).post();
  }

  /**
   * @param application
   */
  public static updateApplication(application: Application): RequestContext<Application> {
    return new AdminApiRequest<Application>(ApiResource.APPLICATIONS, application?.id || 0)
      .withAuth()
      .withBody(application)
      .put();
  }

  /**
   * @param id
   */
  public static deleteApplication(id: number): RequestContext<null> {
    return new AdminApiRequest<null>(ApiResource.APPLICATIONS, id).withAuth().delete();
  }

  /**
   * Undelete a deleted application
   * @param id
   */
  public static undeleteApplication(id: number): RequestContext<null> {
    return new AdminApiRequest<null>(ApiResource.APPLICATIONS, id, "undelete").withAuth().post();
  }

  // ****************************
  // ******* SCHEMA API *********
  // ****************************

  /**
   *
   */
  public static getAllSchemas(
    queryParams?: Partial<GetAllSchemasQueryParams>
  ): RequestContext<PaginationResult<Schema>> {
    return new AdminApiRequest<PaginationResult<Schema>>(ApiResource.SCHEMAS).withParams(queryParams).withAuth().get();
  }

  /**
   * @param id
   */
  public static getOneSchema(id: number): RequestContext<Schema> {
    return new AdminApiRequest<Schema>(ApiResource.SCHEMAS, id).withAuth().get();
  }

  /**
   * @param schema
   */
  public static createSchema(schema: Partial<Schema>): RequestContext<Schema> {
    return new AdminApiRequest<Schema>(ApiResource.SCHEMAS).withAuth().withBody(schema).post();
  }

  /**
   * @param schema
   */
  public static updateSchema(schema: Schema): RequestContext<Schema> {
    return new AdminApiRequest<Schema>(ApiResource.SCHEMAS, schema?.id || 0).withAuth().withBody(schema).put();
  }

  /**
   * @param id
   */
  public static deleteSchema(id: number): RequestContext<null> {
    return new AdminApiRequest<null>(ApiResource.SCHEMAS, id).withAuth().delete();
  }

  /**
   * Undelete a deleted schema
   * @param id
   */
  public static undeleteSchema(id: number): RequestContext<null> {
    return new AdminApiRequest<null>(ApiResource.SCHEMAS, id, "undelete").withAuth().post();
  }

  /**
   * @param id
   * @param version
   */
  public static getSpecificSchemaVersion(id: number, version: number): RequestContext<Schema> {
    return new AdminApiRequest<Schema>(ApiResource.SCHEMAS, id, "version", version).withAuth().get();
  }

  /**
   * Retrieves all versions/revisions (the history) of a schema
   * @param id
   */
  public static getSchemaHistory(id: number): RequestContext<Array<Schema>> {
    return new AdminApiRequest<Array<Schema>>(ApiResource.SCHEMAS, id, "history").withAuth().get();
  }

  public static getGlobalDefinitions(): RequestContext<Schema> {
    return new AdminApiRequest<Schema>(ApiResource.SCHEMAS, "global-definitions").withAuth().get();
  }

  // ****************************
  // ****** DOCUMENT API ********
  // ****************************

  /**
   *
   */
  public static getAllDocuments(
    queryParams?: Partial<GetAllDocumentsQueryParams>
  ): RequestContext<PaginationResult<Document>> {
    return new AdminApiRequest<PaginationResult<Document>>(ApiResource.DOCUMENTS)
      .withParams(queryParams)
      .withAuth()
      .get();
  }

  /**
   * @param id
   */
  public static getOneDocument(id: number): RequestContext<Document> {
    return new AdminApiRequest<Document>(ApiResource.DOCUMENTS, id).withAuth().get();
  }

  /**
   * @param schema
   */
  public static createDocument(schema: Partial<Document>): RequestContext<Document> {
    return new AdminApiRequest<Document>(ApiResource.DOCUMENTS).withAuth().withBody(schema).post();
  }

  /**
   * @param schema
   */
  public static updateDocument(schema: Document): RequestContext<Document> {
    return new AdminApiRequest<Document>(ApiResource.DOCUMENTS, schema?.id || 0).withAuth().withBody(schema).put();
  }

  /**
   * @param id
   */
  public static deleteDocument(id: number): RequestContext<null> {
    return new AdminApiRequest<null>(ApiResource.DOCUMENTS, id).withAuth().delete();
  }

  /**
   * Undelete a deleted document
   * @param id
   */
  public static undeleteDocument(id: number): RequestContext<null> {
    return new AdminApiRequest<null>(ApiResource.DOCUMENTS, id, "undelete")
      .withParams({ include_deleted: true })
      .withAuth()
      .post();
  }

  /**
   * Get all versions/revisions (the history) of a document
   * @param id
   */
  public static getDocumentHistory(id: number): RequestContext<Array<Document>> {
    return new AdminApiRequest<Array<Document>>(ApiResource.DOCUMENTS, id, "revisions").withAuth().get();
  }

  /**
   * Removes qr-codes, from the given document, which are no longer connected to it.
   * @param id
   */
  public static cleanUpQRCodesInDocument(id: number): RequestContext<Document> {
    return new AdminApiRequest<Document>(ApiResource.DOCUMENTS, id, "qr_code_cleanup").withAuth().get();
  }

  /**
   *
   * @param query
   */
  public static searchDocuments(query: string): RequestContext<DocumentSearchHit[]> {
    return new AdminApiRequest<DocumentSearchHit[]>(ApiResource.DOCUMENTS, "search")
      .withParams({
        q: query,
      })
      .withAuth()
      .get();
  }

  /**
   *
   * @param schema_id
   */
  public static reindexDocumentSchema(schema_id?: number): RequestContext<null> {
    return new AdminApiRequest<null>(ApiResource.DOCUMENTS, "reindex").withParams({ schema_id }).withAuth().get();
  }

  // ****************************
  // ******** MEDIA API *********
  // ****************************

  /**
   *
   */
  public static getAllMedia(queryParams?: Partial<GetAllMediaQueryParams>): RequestContext<PaginationResult<Media>> {
    return new AdminApiRequest<PaginationResult<Media>>(ApiResource.MEDIA).withParams(queryParams).withAuth().get();
  }

  /**
   * @param id
   */
  public static getOneMedia(id: number): RequestContext<Media> {
    return new AdminApiRequest<Media>(ApiResource.MEDIA, id).withAuth().get();
  }

  /**
   * @param queryParams
   */
  public static getMediaUnique(queryParams: GetMediaUniqueQueryParams): RequestContext<Media> {
    return new AdminApiRequest<Media>(ApiResource.MEDIA, "unique").withParams(queryParams).withAuth().get();
  }

  /**
   * @param media
   */
  public static createMedia(media: Partial<Media>): RequestContext<Media> {
    return new AdminApiRequest<Media>(ApiResource.MEDIA).withAuth().withBody(media).post();
  }

  /**
   * @param media
   */
  public static updateMedia(media: Media): RequestContext<Media> {
    return new AdminApiRequest<Media>(ApiResource.MEDIA, media?.id || 0).withAuth().withBody(media).put();
  }

  /**
   * @param id
   */
  public static deleteMedia(id: number): RequestContext<null> {
    return new AdminApiRequest<null>(ApiResource.MEDIA, id).withAuth().delete();
  }

  /**
   * @param id
   */
  public static undeleteMedia(id: number): RequestContext<null> {
    return new AdminApiRequest<null>(ApiResource.MEDIA, id, "undelete")
      .withParams({ include_deleted: true })
      .withAuth()
      .post();
  }

  public static getCreditTypes(): RequestContext<CreditType> {
    return new AdminApiRequest<CreditType>(ApiResource.MEDIA, "credit-types").withAuth().get();
  }

  public static getSupportedMediaTypes(): RequestContext<MimeTypes> {
    return new AdminApiRequest<MimeTypes>(ApiResource.MEDIA, "mime-types").withAuth().get();
  }

  /**
   * Get list of media urls (src and thumbnail_src) from a list of media_ids
   */
  public static getMediaUrls(
    queryParams?: Partial<GetMediaUrlsQueryParams>
  ): RequestContext<NonPaginatedItemsResult<Media>> {
    return new AdminApiRequest<NonPaginatedItemsResult<Media>>(ApiResource.MEDIA, "media_urls")
      .withParams(queryParams)
      .withAuth()
      .get();
  }

  // ****************************
  // ****** QRCODE API ********
  // ****************************

  /**
   *
   */
  public static getAllQrCodes(
    queryParams?: Partial<GetAllQrCodesQueryParams>
  ): RequestContext<PaginationResult<QrCode>> {
    return new AdminApiRequest<PaginationResult<QrCode>>(ApiResource.QRCODES).withParams(queryParams).withAuth().get();
  }

  /**
   * @param id
   */
  public static getOneQrCode(id: number): RequestContext<QrCode> {
    return new AdminApiRequest<QrCode>(ApiResource.QRCODES, id).withAuth().get();
  }

  /**
   * @param code
   */
  public static getOneQrCodeByCode(code: string): RequestContext<QrCode> {
    return new AdminApiRequest<QrCode>(ApiResource.QRCODES, code, "code").withAuth().get();
  }

  /**
   * @param queryParams
   */
  public static getQRCodeImage(queryParams: Partial<GetQRCodeImageQueryParams>): RequestContext<any> {
    return new AdminApiRequest<any>(ApiResource.QRCODES, "image").withParams(queryParams).withAuth().get();
  }

  /**
   * @param qrCodeData
   */
  public static createQrCode(qrCodeData: Partial<QrCode>): RequestContext<QrCode> {
    return new AdminApiRequest<QrCode>(ApiResource.QRCODES).withAuth().withBody(qrCodeData).post();
  }

  /**
   * @param qrCodeData
   */
  public static updateQrCode(qrCodeData: QrCode): RequestContext<QrCode> {
    return new AdminApiRequest<QrCode>(ApiResource.QRCODES, qrCodeData?.id || 0).withAuth().withBody(qrCodeData).put();
  }

  /**
   * @param id
   */
  public static deleteQrCode(id: number): RequestContext<null> {
    return new AdminApiRequest<null>(ApiResource.QRCODES, id).withAuth().delete();
  }

  /**
   * Undelete a deleted qr-code
   * @param id
   */
  public static undeleteQrCode(id: number): RequestContext<null> {
    return new AdminApiRequest<null>(ApiResource.QRCODES, id, "undelete").withAuth().post();
  }

  // ************************************************
  // ******** DOCUMENT PROCESSED FIELDS API *********
  // ****************************+*******************

  /**
   * Returns processed fields (title, description and media data) for a given document.
   *
   * @param document_id
   */
  public static getProcessedFieldsOfDocument(document_id: number): RequestContext<DocumentProcessedFields> {
    return new AdminApiRequest<DocumentProcessedFields>(ApiResource.DOCUMENTS, document_id, "fields").withAuth().get();
  }

  /**
   * Returns processed fields (title, description and media data) from list of documents.
   *
   * @param queryParams
   */
  public static getProcessedFieldsOfDocuments(
    queryParams: GetProcessedFieldsOfDocumentsQueryParams
  ): RequestContext<DocumentProcessedFields[]> {
    return new AdminApiRequest<DocumentProcessedFields[]>(ApiResource.DOCUMENTS, "fields")
      .withAuth()
      .withParams(queryParams)
      .get();
  }

  // ****************************
  // ******** DOCUMENT RELATIONS API *********
  // ****************************

  /**
   * Returns all references of a document as an array of relationship details.
   *
   * @param document_id
   */
  public static getRelationsOfDocument(document_id: number): RequestContext<DocumentRelation[]> {
    return new AdminApiRequest<DocumentRelation[]>(ApiResource.DOCUMENTS, document_id, "relations").withAuth().get();
  }

  /**
   * Returns relationship details of the document.
   *
   * @param document_id
   */
  public static getDocumentAsRelation(document_id: number): RequestContext<DocumentRelation> {
    return new AdminApiRequest<DocumentRelation>(ApiResource.DOCUMENTS, document_id, "relation").withAuth().get();
  }

  /**
   * Returns relationship details of a list of documents.
   *
   * @param queryParams
   */
  public static getDocumentsAsRelation(
    queryParams: GetDocumentsAsRelationQueryParams
  ): RequestContext<DocumentRelation[]> {
    return new AdminApiRequest<DocumentRelation[]>(ApiResource.DOCUMENTS, "relations")
      .withAuth()
      .withParams(queryParams)
      .get();
  }

  /**
   * Upload a file to DMS. set ONE OF `ownerId` or `instanceId` to specify the permission for file
   *
   * @param file The file to upload
   * @param ownerId The ID of the owner this file should be available for
   * @param instanceId The ID of the instance this file should be available for
   * @param title The title of the media element
   * @param dimension The image-dimensions
   * @param dominantColor The calculated dominant color of an image
   */
  public static uploadToDMS(
    file: File | Blob,
    ownerId?: number,
    instanceId?: number,
    title?: string,
    dimension?: string,
    dominantColor?: string
  ): RequestContext<Media> {
    const fd = new FormData();
    fd.append("title", title || (file instanceof File ? file.name : "blob"));
    fd.append("file", file);
    if (ownerId) {
      fd.append("owner_id", String(ownerId));
    }
    if (instanceId) {
      fd.append("application_instance_id", String(instanceId));
    }
    if (dimension) {
      fd.append("dimensions", String(dimension));
    }
    if (dominantColor) {
      fd.append("dominant_color", String(dominantColor));
    }
    return new AdminApiRequest<Media>(ApiResource.MEDIA, "upload-dms").withAuth().withFormData(fd).post();
  }

  /**
   *
   * @param searchParams
   */
  public static searchDM(searchParams: SearchDMQueryParams): RequestContext<PaginationResult<DMSearchResult>> {
    return new AdminApiRequest<PaginationResult<DMSearchResult>>(ApiResource.MEDIA, "dm", "search")
      .withAuth()
      .withParams(searchParams)
      .get();
  }

  /**
   *
   * @param identifier
   */
  public static getPlaybackUrls(identifier: string): RequestContext<DMSPlaybackUrls> {
    return new AdminApiRequest<DMSPlaybackUrls>(ApiResource.MEDIA, "playback", identifier).withAuth().get();
  }

  /**
   *
   * @param identifier
   */
  public static getMediaUploadStatus(identifier: string): RequestContext<DMSUploadStatus> {
    return new AdminApiRequest<DMSUploadStatus>(ApiResource.MEDIA, "status", identifier).withAuth().get();
  }

  // ****************************
  // ******** HEALTH API ********
  // ****************************

  /**
   * Check the status of the API and sub-systems
   */
  public static healthCheck(): RequestContext<HealthCheck> {
    return new HealthApiRequest<HealthCheck>(ApiResource.HEALTH_CHECK).get();
  }

  // ****************************
  // ******** Portal API ********
  // ****************************

  public static getAllPortalDocuments(
    queryParams?: Partial<GetAllPortalDocuementsQueryParams>
  ): RequestContext<PaginationResult<Document>> {
    return new PortalApiRequest<PaginationResult<Document>>(ApiResource.DOCUMENTS)
      .withParams(queryParams)
      .withApiKey()
      .get();
  }
}

export default Api;
