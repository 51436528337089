import { type FC } from "react";
import { TestModal } from "./TestModal";
import { EditMediaViewModal } from "./EditMediaViewModal";
import { EditDocumentLoaderModal } from "./EditDocumentLoaderModal";
import { DocumentListModalContentModal } from "./DocumentListModalContent";
import { ModalIdProvider } from "@/framework/ModalManager/ModalIdProvider";

export const ModalManager: FC = () => (
  <>
    <TestModal />
    <EditMediaViewModal />
    <ModalIdProvider>
      <EditDocumentLoaderModal />
    </ModalIdProvider>
    <DocumentListModalContentModal />
  </>
);
